import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const GridContainer = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  // mobile viewport bug fix
  min-height: -webkit-fill-available;
`
const Overlay = styled.div`
  position: relative;
  grid-area: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 10vh 2rem; */
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.6) 100%); 
`

export const HeroSection = ({ children }) => {
  return (
    <GridContainer>
      <StaticImage
        src="../images/stock-img-forest-upward.jpg"
        alt="Hero Image"
        placeholder="blurred"
        style={{
          gridArea: '1/1',
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
        }}
      />
      <Overlay>{children}</Overlay>
    </GridContainer>
  )
}
