import * as React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import { P } from '../components/page-elements'
import { HeroSection } from '../components/hero-section'
import { DealsSection } from '../components/deals-section'
import { Link } from '../components/link'


const Main = styled.main`
  background-color: ${({ theme }) => theme.background};
`
const LogoContainer = styled.div`
  max-width: 275px;
  margin-bottom: 10vh;
  @media (min-width: 768px) {
    max-width: 500px;
  }
  @media (min-width: 1024px) and (min-height: 768px) {
    max-width: 700px;
  }
  @media (min-width: 1200px) and (min-height: 1024px) {
    max-width: 1024px;
  }
  /* weird laptop screen size fix */
  @media (max-height: 600px) and (min-width: 900px) {
    margin-top: 10vh;
  }
`

// TODO move CTA Link into reusable component
const CallToAction = styled(Link)`
  padding-left: .5em;
  padding-right: .5em;
  padding-top: .15em;
  padding-bottom: .15em;
  background: ${({theme}) => theme.teal.hex};
  color: ${({theme}) => theme.darkGray.hex};
  font-size: 1.953rem;
  font-weight: 900;
  text-decoration: none;
  box-shadow: 5px 5px 0px 0px hsla(${({theme}) => theme.darkWhite.hsl}, 1);
  transition: all 300ms ease;
  @media(hover: hover) {
    &:hover {
      background: hsla(${({theme}) => theme.darkGray.hsl}, 1);
      color: ${({theme}) => theme.teal.hex};
      box-shadow: 5px 5px 0px 0px hsla(${({theme}) => theme.teal.hsl}, 1);
    }
  }
  @media(min-width: 480px) {
    font-size: 2.441rem;
  }
  @media(min-width: 768px) {
    font-size: 3.052rem;
  }
`
const HeroMessage = styled(P)`
  color: ${({theme}) => theme.darkWhite.hex};
  margin-bottom: 2.5vh;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  padding-left: 2rem;
  padding-right: 2rem;
`

const IndexPage = ({ location }) => {

  return (
    <Layout
      title="Locally Grown Cannabis" 
      pathname={location.pathname}
    >
      <Main>
        <HeroSection>
          <LogoContainer>
            <StaticImage
              src="../images/logo-hero-white.png"
              alt="Easton Cannabis Logo"
              objectFit="contain"
              placeholder="blurred"
            />
          </LogoContainer>
          <HeroMessage>We're open - check out our menu to see our grand opening specials.</HeroMessage>
          <CallToAction to="/products">Order Now 	<span>&gt;</span></CallToAction>
        </HeroSection>
      </Main>
      <DealsSection />
    </Layout>
  )
}

export default IndexPage
